"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
var custom_elements_1 = require("./custom-elements");
var COI = require("./co.inviewport");
var COInViewport = COI.CO.InViewport;
var COInViewportItem = COI.CO.InViewportItem;
var COIL = require("./co.imageloader");
var COImageLoader = COIL.CO.ImageLoader;
var COImage = (function (_super) {
    __extends(COImage, _super);
    function COImage(element) {
        var _this = _super.call(this, element) || this;
        _this.createContainer();
        _this.createImageContainer();
        _this.createLoader();
        return _this;
    }
    COImage.prototype.createContainer = function () {
        var container = document.createElement('div');
        container.classList.add('co-image-container');
        this.element.appendChild(container);
    };
    COImage.prototype.createImageContainer = function () {
        var image = document.createElement('div');
        image.classList.add('image');
        this.element.querySelector('.co-image-container').appendChild(image);
        var img = document.createElement('img');
        image.appendChild(img);
    };
    COImage.prototype.createLoader = function () {
        var loader = document.createElement('div');
        loader.classList.add('loader');
        var icon = document.createElement('div');
        icon.classList.add('icon');
        icon.innerHTML = '<svg version="1.1" id="L5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">\n' +
            '  <circle fill="" stroke="none" cx="26" cy="50" r="6" transform="translate(0 -3.31716)">\n' +
            '    <animateTransform attributeName="transform" dur="1s" type="translate" values="0 15 ; 0 -15; 0 15" repeatCount="indefinite" begin="0.1"></animateTransform>\n' +
            '  </circle>\n' +
            '  <circle fill="" stroke="none" cx="50" cy="50" r="6" transform="translate(0 -6.21144)">\n' +
            '    <animateTransform attributeName="transform" dur="1s" type="translate" values="0 10 ; 0 -10; 0 10" repeatCount="indefinite" begin="0.2"></animateTransform>\n' +
            '  </circle>\n' +
            '  <circle fill="" stroke="none" cx="74" cy="50" r="6" transform="translate(0 -4.89428)">\n' +
            '    <animateTransform attributeName="transform" dur="1s" type="translate" values="0 5 ; 0 -5; 0 5" repeatCount="indefinite" begin="0.3"></animateTransform>\n' +
            '  </circle>\n' +
            '</svg>';
        loader.appendChild(icon);
        this.element.querySelector('.co-image-container').appendChild(loader);
        this.element.querySelector('.co-image-container').classList.add('loading');
    };
    COImage.prototype.onAttributeChange = function () {
        var that = this;
        var url = null;
        if (this.element.hasAttribute('src') && this.element.getAttribute('src')) {
            url = this.element.getAttribute('src');
        }
        if (this.element.hasAttribute('background') && this.element.getAttribute('background')) {
            url = this.element.getAttribute('background');
        }
        if (url) {
            COInViewport.add(new COInViewportItem().setElement(this.element).setCallback(function () {
                that.element.querySelector('.co-image-container').classList.add('loading');
                new COImageLoader().add(url, function (url) {
                    that.element.querySelector('.image').style.backgroundImage = 'url(' + url + ')';
                    if (that.element.hasAttribute('src')) {
                        that.element.querySelector('img').src = url;
                    }
                    that.element.querySelector('.co-image-container').classList.remove('loading');
                    that.element.querySelector('.co-image-container').classList.remove('empty');
                }).execute();
            }));
        }
        else {
            this.element.querySelector('.co-image-container').classList.remove('loading');
            this.element.querySelector('.co-image-container').classList.add('empty');
            this.element.querySelector('.image').style.backgroundImage = '';
            this.element.querySelector('.image img').src = '';
        }
    };
    COImage.prototype.onAdded = function () {
    };
    return COImage;
}(custom_elements_1.CustomElements.CustomElementBase));
exports.COImage = COImage;
