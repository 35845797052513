"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
var handle_response_1 = require("../../../../../../controller/static/ts/handle-response");
var COUR = require("../../../../../../controller/static/ts/co.urlnavigator");
var COURLNavigator = COUR.CO.URLNavigator;
var COW = require("../../../../../../controller/static/ts/co.window");
var COWindow = COW.CO.Window;
var Webapp = (function (_super) {
    __extends(Webapp, _super);
    function Webapp() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Webapp.initScrollListenerForHeader = function () {
        var that = this;
        document.addEventListener('scroll', function () {
            var scrollT = $('body').scrollTop() || $(window).scrollTop();
            if (scrollT > COURLNavigator.rem() * 10) {
                $('body').addClass('small-header');
            }
            else {
                $('body').removeClass('small-header');
            }
        });
    };
    Webapp.initReferences = function (el) {
        $('.block.referenciak .items').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            prevArrow: '<i class="far fa-angle-left"></i>',
            nextArrow: '<i class="far fa-angle-right"></i>',
            responsive: [
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    };
    Webapp.sendForm = function (form) {
        Webapp.disableSubmit(form);
        Webapp.clearResponse(form);
        var that = this;
        var data = $(form).serialize();
        var webapp = this;
        $.ajax({
            url: '/index/send-form',
            data: data,
            method: 'post',
            dataType: "json",
            success: function (data) {
                Webapp.enableSubmit(form);
                var response = Webapp.handleResponse(data, form);
                if (response.isOk()) {
                    COWindow.get(form).close();
                    var w = new COWindow();
                    w.setContent(response.getField('window').message);
                    w.open();
                }
            }
        });
    };
    Webapp.toggleMobileMenu = function () {
        document.body.classList.toggle('menu-opened');
    };
    Webapp.closeMobileMenu = function () {
        document.body.classList.remove('menu-opened');
    };
    return Webapp;
}(handle_response_1.HandleResponse));
exports.Webapp = Webapp;
