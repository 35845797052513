"use strict";
exports.__esModule = true;
var CO;
(function (CO) {
    var Ajax;
    (function (Ajax) {
        var Response = (function () {
            function Response() {
                this.onShowGlobal = function () {
                };
                this.onDismissGlobal = function () {
                };
                this.onSuccess = function () {
                };
                this.onError = function () {
                };
                this.oldResetMessages = function () {
                    if (this.form) {
                        Array.prototype.slice.call(this.form.querySelectorAll("[name]")).forEach(function (el) {
                            el.classList.remove('error');
                            el.classList.remove('success');
                        });
                        Array.prototype.slice.call(this.form.querySelectorAll('.field-response')).forEach(function (el) {
                            el.classList.remove('error');
                            el.classList.remove('success');
                            el.innerHTML = '';
                            el.setAttribute('data', '');
                        });
                        Array.prototype.slice.call(this.form.querySelectorAll('.field-response.auto-created')).forEach(function (el) {
                            el.remove();
                        });
                    }
                };
                this.addOldFieldResponses = function () {
                    this.oldResetMessages();
                    if (this.response.fields.length || this.response.ok == false) {
                        var errors = 0;
                        if (this.form) {
                            for (var i in this.response.fields) {
                                var field = this.response.fields[i];
                                if (typeof (field) != 'object') {
                                    continue;
                                }
                                if (field.type == 'error') {
                                    errors++;
                                }
                                var fieldname = field.name;
                                var fieldname2 = field.name;
                                fieldname = fieldname.replace(/\[/g, '\\[');
                                fieldname = fieldname.replace(/\]/g, '\\]');
                                fieldname2 = fieldname2.replace(/\[/g, '_');
                                fieldname2 = fieldname2.replace(/\]/g, '_');
                                if (field.message != null) {
                                    var fieldResponse = this.form.querySelector('.field-response.' + fieldname2);
                                    var index = field.index || 0;
                                    if (fieldResponse == null) {
                                        var el = $('<div>');
                                        el.addClass('field-response');
                                        el.addClass('auto-created');
                                        el.addClass(fieldname2);
                                        $(this.form).find('[name=' + fieldname + ']').after(el);
                                    }
                                    $(this.form).find('.field-response.' + fieldname2).eq(index).html(field.message);
                                    $(this.form).find('.field-response.' + fieldname2).eq(index).addClass(field.type);
                                    if (field.value !== null) {
                                        $(this.form).find('[name=' + fieldname + ']').val(field.value);
                                    }
                                }
                                if ($(this.form).find('[name=' + fieldname + ']').length > 0) {
                                    $(this.form).find('[name=' + fieldname + ']').addClass(field.type);
                                }
                                else {
                                    $(this.form).find('.' + fieldname).addClass(field.type);
                                }
                            }
                        }
                    }
                };
            }
            Response.prototype.execute = function () {
                this.removeFieldResponses();
                this.addFieldResponses();
                if (this.response.global != null) {
                    this.onShowGlobal(this.response.global);
                }
                if (this.response.ok === true) {
                    this.onSuccess.call(this);
                }
                else {
                    this.onError.call(this);
                }
            };
            Response.prototype.addFieldResponses = function () {
                if (this.fieldAddResponseCallback != null) {
                    for (var _i = 0, _a = this.response.fields; _i < _a.length; _i++) {
                        var field = _a[_i];
                        this.fieldAddResponseCallback.bind(field)();
                    }
                }
            };
            Response.prototype.removeFieldResponses = function () {
                if (this.fieldClearResponseCallback != null) {
                    this.fieldClearResponseCallback();
                }
            };
            Response.prototype.setFieldAddResponse = function (callback) {
                this.fieldAddResponseCallback = callback;
            };
            Response.prototype.setFieldClearResponse = function (callback) {
                this.fieldClearResponseCallback = callback;
            };
            Response.prototype.isOk = function () {
                return this.response.ok;
            };
            Response.prototype.getField = function (name) {
                for (var _i = 0, _a = this.response.fields; _i < _a.length; _i++) {
                    var field = _a[_i];
                    if (field.name === name) {
                        return field;
                    }
                }
                return null;
            };
            Response.prototype.getGlobal = function () {
                return this.response.global;
            };
            Response.prototype.setForm = function (form) {
                this.form = form;
            };
            Response.prototype.setOnSuccess = function (callback) {
                this.onSuccess = callback;
            };
            Response.prototype.setOnError = function (callback) {
                this.onError = callback;
            };
            Response.prototype.setOnDismissGlobal = function (callback) {
                this.onDismissGlobal = callback;
            };
            Response.prototype.setOnShowGlobal = function (callback) {
                this.onShowGlobal = callback;
            };
            Response.prototype.setResponse = function (response) {
                this.response = new CO.Ajax.Response.Data();
                this.response.ok = response.ok;
                if (response.global != null) {
                    var f = new CO.Ajax.Response.Data.Field();
                    f.type = response.global.type;
                    f.message = response.global.message;
                    this.response.global = f;
                }
                else {
                    this.response.global = null;
                }
                if (response.fields == undefined) {
                    return;
                }
                for (var _i = 0, _a = response.fields; _i < _a.length; _i++) {
                    var field = _a[_i];
                    var f = new CO.Ajax.Response.Data.Field();
                    f.name = field.name;
                    f.type = field.type;
                    f.show = field.show;
                    f.message = field.message;
                    f.index = field.index;
                    this.response.fields.push(f);
                }
            };
            return Response;
        }());
        Ajax.Response = Response;
        (function (Response) {
            var Data = (function () {
                function Data() {
                    this.fields = [];
                }
                return Data;
            }());
            Response.Data = Data;
            (function (Data) {
                var Field = (function () {
                    function Field() {
                        this.index = 0;
                    }
                    return Field;
                }());
                Data.Field = Field;
            })(Data = Response.Data || (Response.Data = {}));
        })(Response = Ajax.Response || (Ajax.Response = {}));
    })(Ajax = CO.Ajax || (CO.Ajax = {}));
})(CO = exports.CO || (exports.CO = {}));
