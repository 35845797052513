"use strict";
exports.__esModule = true;
var COA = require("./co.ajax");
var COAjax = COA.CO.Ajax;
var HandleResponse = (function () {
    function HandleResponse() {
    }
    HandleResponse.handleResponse = function (response, form) {
        if (form === void 0) { form = null; }
        var that = this;
        var r = new COAjax.Response();
        r.setResponse(response);
        if (form != null) {
            r.setForm(form);
            r.setFieldAddResponse(function () {
                this.name = this.name.replace(/\[/g, '\\[');
                this.name = this.name.replace(/\]/g, '\\]');
                this.name = this.name.replace(/\./g, '\\.');
                var el = form.querySelectorAll('[data-field-response-block=' + this.name + ']')[this.index];
                if (el) {
                    el.classList.add('error');
                    if (this.message) {
                        el.setAttribute('data-message', this.message);
                    }
                }
            });
            r.setFieldClearResponse(function () {
                HandleResponse.clearResponse(form);
            });
        }
        r.setOnSuccess(function () {
            if (r.getField('redirect') != null) {
                window.location.href = r.getField('redirect').message;
            }
        });
        r.setOnError(function () {
        });
        r.execute();
        return r;
    };
    HandleResponse.disableSubmit = function (form) {
        var submit = form.querySelector('input[type=submit]');
        if (submit) {
            submit.classList.add('loading');
            submit.setAttribute('disabled', 'disabled');
        }
    };
    HandleResponse.enableSubmit = function (form) {
        var submit = form.querySelector('[type=submit]');
        if (submit) {
            submit.removeAttribute('disabled');
            submit.classList.remove('loading');
            submit.style.width = null;
        }
    };
    HandleResponse.clearResponse = function (form) {
        if (form === void 0) { form = null; }
        if (form == null) {
            return;
        }
        var els = form.querySelectorAll('[data-field-response-block]');
        for (var _i = 0, _a = Array.prototype.slice.call(els); _i < _a.length; _i++) {
            var el = _a[_i];
            el.classList.remove('error');
            el.classList.remove('success');
            el.removeAttribute('data-message');
        }
    };
    return HandleResponse;
}());
exports.HandleResponse = HandleResponse;
