'use strict';
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
var custom_elements_1 = require("./custom-elements");
var COInput = (function (_super) {
    __extends(COInput, _super);
    function COInput(element) {
        var _this = _super.call(this, element) || this;
        _this.createLabel();
        _this.createInput();
        _this.createResponseContainer();
        return _this;
    }
    COInput.prototype.createLabel = function () {
        if (this.element.hasAttribute('label')) {
            this.label = document.createElement('label');
            this.label.innerHTML = this.element.getAttribute('label');
            this.element.appendChild(this.label);
            this.element.removeAttribute('label');
        }
    };
    COInput.prototype.createInput = function () {
        this.input = document.createElement('input');
        this.input.type = this.element.getAttribute('type');
        this.input.name = this.element.getAttribute('name');
        this.input.autocomplete = 'nope';
        this.input.setAttribute('autofill', 'off');
        this.element.appendChild(this.input);
        if (this.element.hasAttribute('value')) {
            this.input.setAttribute('value', this.element.getAttribute('value'));
        }
        if (this.element.hasAttribute('placeholder')) {
            this.input.setAttribute('placeholder', this.element.getAttribute('placeholder'));
        }
        this.element.setAttribute('data-field-response-block', this.element.getAttribute('name'));
        this.element.removeAttribute('name');
        this.element.removeAttribute('value');
    };
    COInput.prototype.createResponseContainer = function () {
        var responseContainer = document.createElement('div');
        responseContainer.classList.add('response');
        this.element.appendChild(responseContainer);
    };
    COInput.prototype.onAttributeChange = function () {
        if (this.element.hasAttribute('data-message') && this.element.getAttribute('data-message')) {
            this.element.querySelector('.response').innerHTML = this.element.getAttribute('data-message');
        }
        else {
            var response = this.element.querySelector('.response').innerHTML = '';
        }
    };
    COInput.prototype.onAdded = function () {
    };
    return COInput;
}(custom_elements_1.CustomElements.CustomElementBase));
exports.COInput = COInput;
