"use strict";
exports.__esModule = true;
var CO;
(function (CO) {
    var URLNavigator = (function () {
        function URLNavigator() {
        }
        URLNavigator.init = function (element) {
            if (element === void 0) { element = null; }
            history.scrollRestoration = 'manual';
            this.currentUrl = window.location.pathname;
            if (URLNavigator.popListenerAdded == false) {
                window.addEventListener('popstate', function (event) {
                    if (URLNavigator.currentUrl == window.location.pathname) {
                        URLNavigator.handleHash();
                        return false;
                    }
                    URLNavigator.loadContent(location.pathname, false, event.state ? event.state.scrollTop : null);
                });
                URLNavigator.popListenerAdded = true;
                URLNavigator.addMutationObserver();
            }
            URLNavigator.handleHash();
            if (element != null) {
                URLNavigator.initForA(element);
            }
            else {
                var as = $('a.ajax:not(.urlnavigator-initialized)');
                as.each(function () {
                    URLNavigator.initForA(this);
                });
            }
        };
        URLNavigator.getLastEventOrigin = function () {
            return this.lastEventOrigin;
        };
        URLNavigator.addMutationObserver = function () {
            var config = { childList: true, subtree: true, attributes: true };
            this.mutationObserver = new MutationObserver(function (mutationsList) {
                URLNavigator.mutationObserver.disconnect();
                URLNavigator.init();
                URLNavigator.addMutationObserver();
            });
            URLNavigator.mutationObserver.observe(document, config);
        };
        URLNavigator.handleHash = function () {
            if (this.hashHandled) {
                return;
            }
            this.hashHandled = true;
            var hash = window.location.hash;
            if (hash.length != undefined && hash.length > 0) {
                setTimeout(function () {
                    URLNavigator.gotoBlock(hash.substr(1));
                }, 300);
            }
            else {
            }
        };
        URLNavigator.gotoBlock = function (to, tempVerticalShift) {
            if (tempVerticalShift === void 0) { tempVerticalShift = 0; }
            if (to.indexOf('#') !== -1) {
                to = to.substr(to.indexOf('#') + 1);
            }
            this.scrollToElement(document.querySelector('[data-block=' + to + ']'), URLNavigator.scrollShift, 300, tempVerticalShift);
        };
        URLNavigator.scrollToElement = function (el, verticalShift, time, tempVerticalShift) {
            if (verticalShift === void 0) { verticalShift = 0; }
            if (time === void 0) { time = 300; }
            if (tempVerticalShift === void 0) { tempVerticalShift = 0; }
            if (time == 0) {
                $('html,body').scrollTop(URLNavigator.getElementPosition(el) - verticalShift + tempVerticalShift);
                return;
            }
            var page = $('html,body');
            page.animate({
                scrollTop: URLNavigator.getElementPosition(el) - verticalShift + tempVerticalShift
            }, time, function () {
            });
            setTimeout(function () {
                page.stop();
            }, time + 10);
            return true;
        };
        URLNavigator.setScrollShift = function (value) {
            URLNavigator.scrollShift = value;
        };
        URLNavigator.initForA = function (el) {
            var that = this;
            if ($(el).hasClass('urlnavigator-initialized')) {
                return;
            }
            if ($(el).attr('onclick') === undefined || $(el).attr('onclick').trim() == '') {
                $(el).addClass('urlnavigator-initialized');
                $(el).on('click', function () {
                    if (that.onClick) {
                        that.onClick(this);
                    }
                    if ($(this).attr('href') !== undefined
                        && (($(this).attr('href').indexOf('http://') === -1
                            &&
                                $(this).attr('href').indexOf('https://') === -1)
                            || ($(this).attr('href').indexOf('http://') !== -1
                                && $(this).attr('href').indexOf('http://' + window.location.host) !== -1))) {
                        var to = $(this).attr('href');
                        if (to.indexOf('#') == 0) {
                            to = to.substr(1);
                            history.pushState(null, null, $(this).attr('href'));
                            URLNavigator.gotoBlock(to);
                            return false;
                        }
                        if (to.indexOf('#') !== -1) {
                            var path = to.substr(0, to.indexOf('#'));
                            if (window.location.pathname === path) {
                                history.pushState(null, null, $(this).attr('href'));
                                URLNavigator.gotoBlock(to.substr(to.indexOf('#') + 1));
                                return false;
                            }
                        }
                        else {
                            if (window.location.pathname === to) {
                                URLNavigator.gotoBlock('top');
                                return false;
                            }
                        }
                        if (Modernizr.history) {
                            if ($(this).attr('target') == '_blank') {
                                return true;
                            }
                            var url = $(this).attr('href');
                            URLNavigator.loadContent(url, true, null, EventOrigin.onclick);
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                    else {
                        return true;
                    }
                });
            }
        };
        URLNavigator.loadContent = function (url, addEntry, scrollTo, eventOrigin) {
            if (addEntry === void 0) { addEntry = true; }
            if (scrollTo === void 0) { scrollTo = null; }
            if (eventOrigin === void 0) { eventOrigin = EventOrigin.script; }
            var that = this;
            if (this.onStart != null) {
                this.onStart();
            }
            var direction = addEntry ? Direction.forward : Direction.backward;
            this.lastEventOrigin = eventOrigin;
            URLNavigator.currentUrl = url;
            $.ajax(url, {
                dataType: 'json',
                success: function (data) {
                    URLNavigator.hashHandled = false;
                    that.onSuccess(data, direction, function () {
                        if (scrollTo) {
                            window.scrollTo(0, scrollTo);
                        }
                        else {
                            window.scrollTo(0, 0);
                        }
                    });
                    if (addEntry == true) {
                        history.replaceState({ scrollTop: $(window).scrollTop() }, document.title, location.href);
                        history.pushState(null, document.title, url);
                    }
                }
            });
        };
        URLNavigator.setURL = function (url) {
            history.replaceState(null, null, url);
        };
        URLNavigator.rem = function (base) {
            if (base === void 0) { base = null; }
            if (base == null) {
                base = 2;
            }
            var html = document.getElementsByTagName('html')[0];
            return parseFloat(window.getComputedStyle(html)['fontSize']) / base;
        };
        URLNavigator.setOnSuccess = function (callback) {
            URLNavigator.onSuccess = callback;
        };
        URLNavigator.setOnClick = function (func) {
            URLNavigator.onClick = func;
        };
        URLNavigator.setOnStart = function (func) {
            URLNavigator.onStart = func;
        };
        URLNavigator.changeLayoutClass = function (classname) {
            var dclasses = Array.prototype.slice.call(document.body.classList);
            var re = /layout-/;
            dclasses.forEach(function (c) {
                if (c.match(re)) {
                    if (classname !== c) {
                        document.body.classList.remove(c);
                    }
                    else {
                    }
                }
            });
            document.body.classList.add(classname);
        };
        URLNavigator.changePageClasses = function (pages) {
            var dclasses = Array.prototype.slice.call(document.body.classList);
            var re = /page-/;
            dclasses.forEach(function (c) {
                if (c.match(re)) {
                    if (pages.indexOf(c) !== -1) {
                    }
                    else {
                        document.body.classList.remove(c);
                    }
                }
            });
            for (var _i = 0, pages_1 = pages; _i < pages_1.length; _i++) {
                var cl = pages_1[_i];
                document.body.classList.add(cl);
            }
        };
        URLNavigator.popListenerAdded = false;
        URLNavigator.scrollShift = 0;
        URLNavigator.hashHandled = false;
        URLNavigator.getElementPosition = function (elem) {
            if (elem === void 0) { elem = null; }
            if (elem == null) {
                return 0;
            }
            var location = 0;
            if (elem.offsetParent) {
                do {
                    location += elem.offsetTop;
                    elem = elem.offsetParent;
                } while (elem);
            }
            return location >= 0 ? location : 0;
        };
        return URLNavigator;
    }());
    CO.URLNavigator = URLNavigator;
    ;
    var Direction;
    (function (Direction) {
        Direction[Direction["forward"] = 0] = "forward";
        Direction[Direction["backward"] = 1] = "backward";
    })(Direction = CO.Direction || (CO.Direction = {}));
    var EventOrigin;
    (function (EventOrigin) {
        EventOrigin[EventOrigin["script"] = 0] = "script";
        EventOrigin[EventOrigin["onclick"] = 1] = "onclick";
    })(EventOrigin = CO.EventOrigin || (CO.EventOrigin = {}));
})(CO = exports.CO || (exports.CO = {}));
