"use strict";
exports.__esModule = true;
var CO;
(function (CO) {
    var Generator;
    (function (Generator) {
        var UniqueID = (function () {
            function UniqueID() {
            }
            UniqueID.create = function () {
                this.counter++;
                return 'UID__' + this.counter;
            };
            UniqueID.counter = 0;
            return UniqueID;
        }());
        Generator.UniqueID = UniqueID;
    })(Generator = CO.Generator || (CO.Generator = {}));
})(CO = exports.CO || (exports.CO = {}));
