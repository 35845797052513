"use strict";
exports.__esModule = true;
var custom_elements_1 = require("../../../../../controller/static/ts/custom-elements");
var co_image_1 = require("../../../../../controller/static/ts/co-image");
var co_gallerybox_1 = require("../../../../../controller/static/ts/co-gallerybox");
var COUR = require("../../../../../controller/static/ts/co.urlnavigator");
var COURLNavigator = COUR.CO.URLNavigator;
var sator_1 = require("./components/sator");
exports.Sator = sator_1.Sator;
var webapp_1 = require("./components/webapp");
exports.Webapp = webapp_1.Webapp;
var co_input_1 = require("../../../../../controller/static/ts/co-input");
var co_textarea_1 = require("../../../../../controller/static/ts/co-textarea");
var COW = require("../../../../../controller/static/ts/co.window");
var COWindow = COW.CO.Window;
exports.COWindow = COWindow;
COURLNavigator.init();
COURLNavigator.setScrollShift(7.5 * COURLNavigator.rem());
COURLNavigator.setOnClick(function () {
    webapp_1.Webapp.closeMobileMenu();
});
webapp_1.Webapp.initScrollListenerForHeader();
custom_elements_1.CustomElements.Controller.add('co-image', co_image_1.COImage);
custom_elements_1.CustomElements.Controller.add('co-gallerybox', co_gallerybox_1.COGalleryBox);
custom_elements_1.CustomElements.Controller.add('co-input', co_input_1.COInput);
custom_elements_1.CustomElements.Controller.add('co-textarea', co_textarea_1.COTextarea);
