"use strict";
exports.__esModule = true;
var COG = require("./co.generator");
var COGenerator = COG.CO.Generator;
var CO;
(function (CO) {
    var Window = (function () {
        function Window() {
            this.url = null;
            this.onlyThis = false;
            this.content = null;
            this.contentElement = null;
            this.minHeight = null;
            this.classes = {};
            this.title = null;
            this.previus = null;
            this.onBeforeClose = null;
            this.width = 'auto';
            this.data = {};
            this.setPrevious = function (window) {
                this.previous = window;
                this._onlyThis = true;
                return this;
            };
            this.setUrl = function (url) {
                this.url = url;
                return this;
            };
            this.setClasses = function (classes) {
                this.classes = classes;
                return this;
            };
            this.setMinHeight = function (val) {
                this.minHeight = val;
                return this;
            };
            this.setContent = function (content) {
                this.content = content;
                return this;
            };
            this.setContentElement = function (content) {
                this.contentElement = content;
                return this;
            };
            this.setTitle = function (title) {
                this.title = title;
                return this;
            };
            this.setData = function (data) {
                this.data = data;
                return this;
            };
            this.setOnlyThis = function () {
                this.onlyThis = true;
                return this;
            };
            this.setOnBeforeClose = function (callback) {
                if (callback === void 0) { callback = null; }
                this.onBeforeClose = callback;
                return this;
            };
            this.setWidth = function (width) {
                this.width = width;
                return this;
            };
            this.open = function () {
                var that = this;
                if (this.url != null) {
                    $.ajax({
                        url: this.url,
                        type: 'POST',
                        dataType: 'html',
                        data: this.data,
                        success: function (data, textStatus, jqXHR) {
                            if (that._onlyThis) {
                                CO.Window.closeAll();
                            }
                            CO.Window.windows.push(that);
                            $('<div></div>').attr('id', that.id).css({ display: 'none' }).appendTo('body').html(data);
                            $('#' + that.id).dialog({
                                closeOnEscape: true,
                                title: that.title,
                                modal: true,
                                minHeight: that.minHeight,
                                classes: that.classes,
                                width: that.width,
                                beforeClose: function (el) {
                                    that.close();
                                }
                            });
                            if (that.previous != null) {
                                that.addPreviousButton();
                            }
                        }
                    });
                }
                else if (this.content) {
                    if (that._onlyThis) {
                        CO.Window.closeAll();
                    }
                    CO.Window.windows.push(this);
                    $('<div></div>').attr('id', that.id).css({ display: 'none' }).appendTo('body').html(this.content);
                    $('#' + this.id).dialog({
                        closeOnEscape: true,
                        title: that.title,
                        classes: this.classes,
                        modal: true,
                        minHeight: this.minHeight,
                        width: 'auto',
                        beforeClose: function () {
                        }
                    });
                }
                else if (this.contentElement) {
                    if (that._onlyThis) {
                        CO.Window.closeAll();
                    }
                    CO.Window.windows.push(this);
                    $('<div></div>').attr('id', that.id).css({ display: 'none' }).appendTo('body').append(this.contentElement);
                    $('#' + this.id).dialog({
                        closeOnEscape: true,
                        title: that.title,
                        classes: this.classes,
                        modal: true,
                        minHeight: this.minHeight,
                        width: 'auto',
                        beforeClose: function () {
                        }
                    });
                }
                return this;
            };
            this.close = function () {
                $('#' + this.id).remove();
                var nWindows = new Array();
                for (var i = 0; i < CO.Window.windows.length; i++) {
                    var t = CO.Window.windows[i];
                    if (t.id != this.id) {
                        nWindows.push(t);
                    }
                }
                CO.Window.windows = nWindows;
                var w = this.previous;
                if (w) {
                    w.close();
                }
            };
            this.addPreviousButton = function () {
                var content = $('#' + this.id);
                var title = content.closest('.ui-dialog').find('.ui-dialog-titlebar');
                var btn = $('<button>').addClass('ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-back');
                title.addClass('has-back');
                title.prepend(btn);
                var that = this;
                btn.on('click', function () {
                    that.previous.onlyThis().open();
                });
            };
            if (typeof $.ui === 'undefined') {
                console.error("jQuery UI nincs betöltve.");
                return;
            }
            this.id = COGenerator.UniqueID.create();
            this.data = {};
        }
        ;
        Window.getById = function (id) {
            for (var _i = 0, _a = CO.Window.windows; _i < _a.length; _i++) {
                var w = _a[_i];
                if (w.getId() == id) {
                    return w;
                }
            }
            return null;
        };
        Window.prototype.getId = function () {
            return this.id;
        };
        Window.getLast = function () {
            if (CO.Window.windows.length == 0) {
                return null;
            }
            var w = CO.Window.windows[CO.Window.windows.length - 1];
            return w;
        };
        Window.windows = new Array();
        Window.get = function (el) {
            var id = el.closest('.ui-dialog-content').getAttribute('id');
            var w = CO.Window.getById(id);
            return w;
        };
        Window.closeAll = function () {
            for (var i = 0; i < CO.Window.windows.length; i++) {
                var w = CO.Window.windows[i];
                w.close();
            }
        };
        return Window;
    }());
    CO.Window = Window;
})(CO = exports.CO || (exports.CO = {}));
