"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
var COL = require("./co-lightbox");
var COLightBox = COL.CO.LightBox;
var custom_elements_1 = require("./custom-elements");
var COGalleryBox = (function (_super) {
    __extends(COGalleryBox, _super);
    function COGalleryBox(element) {
        var _this = _super.call(this, element) || this;
        _this.items = [];
        _this.thumbnailUrl = null;
        _this.thumbnail = null;
        _this.icon = null;
        _this.readItems();
        return _this;
    }
    COGalleryBox.prototype.readItems = function () {
        var that = this;
        Array.prototype.slice.call(this.element.querySelectorAll('co-item')).forEach(function (el) {
            if (that.thumbnailUrl == null) {
                that.thumbnailUrl = el.getAttribute('thumbnail');
            }
            that.items.push(el.getAttribute('url'));
            el.remove();
        });
        if (this.thumbnailUrl) {
            this.initBox();
        }
    };
    COGalleryBox.prototype.initBox = function () {
        this.thumbnail = document.createElement('co-image');
        if (this.element.hasAttribute('data-type') == false) {
            throw 'COGalleryBox: data-type attributum nincs megadva (src, background)';
        }
        if (this.element.getAttribute('data-type') == 'src') {
            this.thumbnail.setAttribute('src', this.thumbnailUrl);
        }
        else {
            this.thumbnail.setAttribute('background', this.thumbnailUrl);
        }
        this.element.appendChild(this.thumbnail);
        this.addClickListener();
        this.addIcon();
    };
    COGalleryBox.prototype.addIcon = function () {
        var that = this;
        this.icon = document.createElement('div');
        this.icon.classList.add('icon');
        this.icon.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="24.531" viewBox="0 0 32 24.531"><g fill="#fff"><path d="M0 0v19.198h3.735v-2.13H2.136V2.136h22.396v1.599h2.135V0z"/><path d="M5.333 24.53h26.668V5.334H5.333z"/></g></svg>';
        this.element.appendChild(this.icon);
        this.icon.addEventListener('click', function () {
            that.createSlideshow();
        });
    };
    COGalleryBox.prototype.onAttributeChange = function () {
    };
    COGalleryBox.prototype.addClickListener = function () {
        var that = this;
        this.thumbnail.addEventListener('click', function () {
            that.loadLightbox();
        });
    };
    COGalleryBox.prototype.loadLightbox = function () {
        var lightbox = new COLightBox();
        this.items.forEach(function (url) {
            lightbox.addItem(url);
        });
        lightbox.open();
    };
    COGalleryBox.prototype.createSlideshow = function () {
    };
    COGalleryBox.prototype.onAdded = function () {
    };
    return COGalleryBox;
}(custom_elements_1.CustomElements.CustomElementBase));
exports.COGalleryBox = COGalleryBox;
