"use strict";
exports.__esModule = true;
var COL = require("../../../../../../controller/static/ts/co-lightbox");
var COLightBox = COL.CO.LightBox;
var COW = require("../../../../../../controller/static/ts/co.window");
var COWindow = COW.CO.Window;
var Sator = (function () {
    function Sator(el, name) {
        this.el = el;
        this.name = name;
        this.initGallery();
        this.initButton();
    }
    Sator.prototype.initGallery = function () {
        var lightbox = new COLightBox();
        var links = Array.prototype.slice.call(this.el.querySelectorAll('.cc .right .images a'));
        var index = 0;
        links.forEach(function (link) {
            lightbox.addItem(link.getAttribute('href'));
            link.addEventListener('click', function (event) {
                lightbox.open(index);
                event.preventDefault();
            });
            index++;
        });
    };
    Sator.prototype.initButton = function () {
        var that = this;
        var button = this.el.querySelector('.cc .right a.button');
        button.addEventListener('click', function (el) {
            var w = new COWindow();
            w.setUrl('/index/window-form');
            w.setData({
                form: that.name
            });
            w.open();
            event.preventDefault();
        });
    };
    return Sator;
}());
exports.Sator = Sator;
