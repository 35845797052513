'use strict';
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
var custom_elements_1 = require("./custom-elements");
var COTextarea = (function (_super) {
    __extends(COTextarea, _super);
    function COTextarea(element) {
        var _this = _super.call(this, element) || this;
        _this.createInput();
        _this.createLabel();
        _this.createResponseContainer();
        return _this;
    }
    COTextarea.prototype.createLabel = function () {
        if (this.element.hasAttribute('label')) {
            this.label = document.createElement('label');
            this.label.innerHTML = this.element.getAttribute('label');
            this.element.insertAdjacentElement("afterbegin", this.label);
            this.element.removeAttribute('label');
        }
    };
    COTextarea.prototype.createInput = function () {
        this.textarea = document.createElement('textarea');
        this.textarea.name = this.element.getAttribute('name');
        while (this.element.childNodes.length) {
            this.textarea.appendChild(this.element.childNodes[0]);
        }
        if (this.element.hasAttribute('grow')) {
            this.textarea.style.minHeight = '0px';
        }
        this.textarea.addEventListener('keyup', this);
        this.element.appendChild(this.textarea);
        if (this.element.hasAttribute('placeholder')) {
            this.textarea.setAttribute('placeholder', this.element.getAttribute('placeholder'));
        }
        this.element.setAttribute('data-field-response-block', this.element.getAttribute('name'));
        this.element.removeAttribute('name');
        this.element.removeAttribute('value');
    };
    COTextarea.prototype.createResponseContainer = function () {
        var responseContainer = document.createElement('div');
        responseContainer.classList.add('response');
        this.element.appendChild(responseContainer);
    };
    COTextarea.prototype.onAttributeChange = function () {
        if (this.element.hasAttribute('data-message') && this.element.getAttribute('data-message')) {
            this.element.querySelector('.response').innerHTML = this.element.getAttribute('data-message');
        }
        else {
            var response = this.element.querySelector('.response').innerHTML = '';
        }
    };
    COTextarea.prototype.handleEvent = function (evt) {
        switch (evt.type) {
            case 'keyup':
                this.keyUp(evt);
        }
    };
    COTextarea.prototype.keyUp = function (evt) {
        if (this.element.hasAttribute('grow')) {
            this.textarea.style.height = "1px";
            this.textarea.style.height = this.textarea.scrollHeight + "px";
        }
        if (evt.key == 'Enter') {
            if (this.element.hasAttribute('autopost') && this.element.getAttribute('autopost') == 'false') {
                return;
            }
            if (evt.shiftKey == false) {
                var form = this.element.closest('form');
                form.querySelector('input[type="submit"]').focus();
                form.querySelector('input[type="submit"]').click();
            }
        }
    };
    COTextarea.prototype.onAdded = function () {
    };
    return COTextarea;
}(custom_elements_1.CustomElements.CustomElementBase));
exports.COTextarea = COTextarea;
